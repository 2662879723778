.decoration-matrix {
  /* this should be in the global stylesheet */ }
  .decoration-matrix :root {
    --color-juri-lighter: #9ba2a6;
    --color-juri: #37454d;
    --font-size--tiny: 12px;
    --font-size--h-tiny: 14px;
    --font-size--small: 20px;
    /* --box-shadow: 0 1px 4px 0 rgba(41, 51, 57, 0.5); */ }
  .decoration-matrix .c-hide {
    display: none; }
  .decoration-matrix .c-show {
    display: initial; }
  .decoration-matrix .removing {
    -webkit-animation: fadeOut 500ms;
    -moz-animation: fadeOut 500ms;
    animation: fadeOut 500ms; }
  .decoration-matrix .c-hidden-delete {
    visibility: hidden; }
    .decoration-matrix .c-hidden-delete :hover,
    .decoration-matrix .c-hidden-delete :focus,
    .decoration-matrix .c-hidden-delete a:hover,
    .decoration-matrix .c-hidden-delete a:focus {
      visibility: visible; }
  .decoration-matrix #admin-nav {
    border: none; }
  .decoration-matrix .decorator-selection {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
    width: calc(850px + 100vw - 1024px);
    position: fixed; }
  @media screen and (min-width: 1025px) {
    .decoration-matrix.main-content {
      padding-left: 11em !important; }
    .decoration-matrix .decorator-selection {
      position: fixed;
      padding-right: 11em !important; }
    .decoration-matrix .decorator-info-p {
      margin-top: 6em !important; }
    .decoration-matrix .add-btn-p {
      width: 30%; }
    .decoration-matrix .edit-done-btn-p {
      width: 30%; } }
  @media screen and (min-width: 861px) and (max-width: 1024px) {
    .decoration-matrix.main-content {
      padding-left: 11em !important; }
    .decoration-matrix .decorator-selection {
      position: fixed;
      padding-right: 11em !important; }
    .decoration-matrix .decorator-info-p {
      margin-top: 12em !important; }
    .decoration-matrix .add-btn-p {
      width: 30% !important; }
    .decoration-matrix .edit-done-btn-p {
      width: 30% !important; } }
  @media screen and (min-width: 641px) and (max-width: 860px) {
    .decoration-matrix.main-content {
      padding-left: 11em !important; }
    .decoration-matrix .decorator-selection {
      position: fixed;
      padding-right: 11em !important; }
    .decoration-matrix .decorator-info-p {
      margin-top: 12em !important; }
    .decoration-matrix .add-btn-p {
      width: 60% !important; }
    .decoration-matrix .edit-done-btn-p {
      width: 40% !important; } }
  @media screen and (min-width: 356px) and (max-width: 640px) {
    .decoration-matrix.main-content {
      padding-left: 0 !important; }
    .decoration-matrix .decorator-selection {
      width: 100% !important;
      margin-left: 0 !important;
      padding-right: 0 !important; }
    .decoration-matrix .decorator-info-p {
      margin-top: 10em !important; }
    .decoration-matrix .add-btn-p {
      width: 50% !important; }
    .decoration-matrix .edit-done-btn-p {
      width: 50% !important; } }
  @media screen and (max-width: 355px) {
    .decoration-matrix.main-content {
      padding-left: 0 !important; }
    .decoration-matrix .decorator-selection {
      width: 100% !important;
      margin-left: 0 !important;
      padding-right: 0 !important; }
    .decoration-matrix .decorator-info-p {
      margin-top: 14em !important; }
    .decoration-matrix .add-btn-p,
    .decoration-matrix .edit-done-btn-p,
    .decoration-matrix #add-btn,
    .decoration-matrix #edit-done-btn,
    .decoration-matrix #edit-done-btn {
      width: 100% !important;
      margin-right: 0 !important;
      margin-left: 0 !important; } }
  .decoration-matrix .card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    background: white; }
    .decoration-matrix .card h4 {
      font-family: "museo-sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-weight: bold;
      text-align: left;
      float: left; }
  .decoration-matrix .resku h4 {
    font-family: "museo-sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    text-align: left;
    float: left; }
  .decoration-matrix .charge-type-select {
    text-align: left; }
  .decoration-matrix #my-table > div > div {
    border: 1px solid #ccd5da;
    box-shadow: none !important; }
  .decoration-matrix .primary-toggle {
    background: #DAE9EE;
    border-radius: 50px;
    display: flex;
    justify-content: space-between; }
    .decoration-matrix .primary-toggle a {
      font-size: 14px;
      padding: 3px 12px;
      font-weight: bold !important; }
    .decoration-matrix .primary-toggle .inactive-toggle {
      padding: 3px 12px;
      background-color: #DAE9EE;
      color: #02c0da;
      border-radius: 30px;
      display: flex;
      align-content: center; }
    .decoration-matrix .primary-toggle .active-toggle {
      padding: 3px 12px;
      background-color: #02c0da;
      color: white;
      border-radius: 30px;
      display: flex;
      align-content: center; }
  .decoration-matrix .button {
    background: #02c0da !important;
    font-family: 'skufont-regular', sans-serif;
    padding: .85em 1em; }
  .decoration-matrix .secondary-button {
    font-family: 'skufont-demibold', sans-serif;
    color: #02c0da !important;
    background: white !important;
    border: 3px solid #02c0da !important;
    padding: .85em 1em;
    border-radius: 3px; }
  .decoration-matrix .secondary-button:hover {
    color: #02c0da !important;
    background: #E6EFF2 !important; }
  .decoration-matrix .resku input[type="text"],
  .decoration-matrix .resku input[type="number"],
  .decoration-matrix .resku textarea {
    border: 1px solid #ABC7D1;
    border-radius: 5px;
    color: #52585C;
    background-color: white; }
  .decoration-matrix .resku input[type="text"]:focus,
  .decoration-matrix .resku input[type="number"]:focus,
  .decoration-matrix .resku textarea:focus {
    border: 2px solid #02c0da;
    background-color: white;
    box-shadow: 0 1px 4px #fafafa;
    border-radius: 5px;
    color: #52585c; }
  .decoration-matrix input::placeholder {
    color: #a4abae; }
  .decoration-matrix .error {
    color: red !important; }
  .decoration-matrix input.error::placeholder {
    color: red !important;
    font-weight: bold; }
  .decoration-matrix .cta-button {
    color: white;
    background-color: #fa237c !important; }
  .decoration-matrix .cta-button:hover {
    color: white;
    background-color: #CF1360 !important;
    border-color: #CF1360 !important; }
  .decoration-matrix .cta-button:active {
    color: white;
    background-color: #B21154 !important;
    border-color: #B21154 !important; }
  .decoration-matrix .btn {
    background-color: #02C0DA; }
  .decoration-matrix .btn:hover {
    background-color: #00A8BF !important;
    border-color: #00A8BF !important; }
  .decoration-matrix .btn:active {
    background-color: #00889B !important;
    border-color: #00889B !important; }
  .decoration-matrix .c-table--bottom-right-grid {
    overflow-y: hidden !important; }
